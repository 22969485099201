import { StorageWrapper } from '../storage-wrapper';
import { isNil } from 'lodash';

const baseHeaders = {
  'content-type': 'application/json',
};

const getHeaders = () => {
  const headers = {
    ...baseHeaders,
  };
  const token = StorageWrapper.getAuthToken();
  if (!isNil(token)) {
    Object.assign(headers, {
      authorization: token,
    });
  }
  return headers;
};

const getCustomerHeaders = () => {
  return {
    'content-type': 'application/json',
  };
};

const getAuthIntermediateHeaders = () => {
  const headers = {
    ...baseHeaders,
  };
  const token = StorageWrapper.getIntermediateToken();
  if (!isNil(token)) {
    Object.assign(headers, {
      authorization: token,
    });
  }
  return headers;
};

const getAnyAuthHeaders = () => {
  const headers = {
    ...baseHeaders,
  };
  let token = StorageWrapper.getAuthToken() || StorageWrapper.getIntermediateToken();
  if (!isNil(token)) {
    Object.assign(headers, {
      authorization: token,
    });
  }
  return headers;
};
export { getHeaders, getCustomerHeaders, getAuthIntermediateHeaders, getAnyAuthHeaders };
