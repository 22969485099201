import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useAppState } from '../../state';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 10px',
  },
}));

export default function ResendOneTimePasscode() {
  const { resendOTPRequest } = useAppState();
  const history = useHistory();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const classes = useStyles();

  const resendOneTimePasscode = async () => {
    // console.log('resendOneTimePasscode');
    setIsSendingRequest(true);
    resendOTPRequest()
      .then(() => {
        setIsSendingRequest(false);
      })
      .catch(() => {
        setIsSendingRequest(false);
        history.replace({ pathname: '/login' });
      });
  };

  return (
    <Button className={classes.submitButton} onClick={resendOneTimePasscode} disabled={isSendingRequest}>
      {isSendingRequest && <CircularProgress size={20} />} Resend code
    </Button>
  );
}
