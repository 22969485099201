import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  // DateInput,
  // ReferenceManyField,
  // Datagrid,
  // TextField,
  // DateField,
  EditButton,
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled source="iid" validate={required()} />
      <TextInput source="email" validate={email()} />
      <TextInput source="name" validate={required()} />
      <TextInput source="whatsappNumber" />
      <TextInput source="mobileNumber" />
      <EditButton />
    </SimpleForm>
  </Edit>
);
