import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { ActivitiesListFilter } from './ActivitiesListFilter';

export const ActivitiesList = props => (
  <List {...props} filters={<ActivitiesListFilter />}>
    <Datagrid>
      <TextField source="brandCodename" />
      <TextField source="roomName" />
      <TextField source="participantIdentity" />
      <TextField source="participantStatus" />
      <TextField source="roomEventType" />
      <TextField source="trackKind" />
      <TextField source="roomDuration" />
      <DateField source="_created_at" showTime />
    </Datagrid>
  </List>
);
