import React, { createContext, useContext, useReducer, useState } from 'react';
import {
  ActivityContext,
  AgentDetails,
  ApiBaseResponse,
  CustomerDetails,
  RoomDetails,
  RoomType,
  UserActivityType,
} from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useVolletoAuth from './useVolletoAuth/useVolletoAuth';
import useConferenceSession from './useConferenceSession/useConferenceSession';
import { BrandConfig } from '../apis/conference/conference-types';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, roomName: string): Promise<string>;
  createRoom(customerDetails: CustomerDetails): Promise<RoomDetails>;
  user?: AgentDetails;
  signIn?(email: string, password: string): Promise<void>;
  verifyOTP?(passcode: string): Promise<void>;
  resetPasswordRequestForAgent(email: string): Promise<void>;
  setNewPasswordRequestForAgent(perishableToken: string, newPassword: string): Promise<void>;
  validateNewPasswordToken(perishableToken: string): Promise<ApiBaseResponse>;
  logUserActivity(action: UserActivityType, context?: ActivityContext): Promise<void>;
  resendOTPRequest(): Promise<ApiBaseResponse>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isIntermediateAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  fetchCustomerSession: (sessionToken: string) => Promise<any>;
  brandConfig: BrandConfig;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    ...useVolletoAuth(),
    ...useConferenceSession(),
  } as StateContextType;

  // if (process.env.REACT_APP_SET_AUTH === 'firebase') {
  //   contextValue = {
  //     ...contextValue,
  //     ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //   };
  // } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
  //   contextValue = {
  //     ...contextValue,
  //     ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //   };
  // } else {
  //   contextValue = {
  //     ...contextValue,
  //     // getToken: async (identity, roomName) => {
  //     //   const headers = new window.Headers();
  //     //   const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
  //     //   const params = new window.URLSearchParams({ identity, roomName });
  //     //
  //     //   return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
  //     // },
  //     ...useVolletoAuth(),
  //   };
  // }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
