import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import AppStateProvider from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import theme from './theme';
import './types';
import { SessionProvider } from './components/SessionProvider';
import AdminApp from './admin-app/AdminApp';

import * as serviceWorker from './serviceWorker.js';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginPage from './components/LoginPage/LoginPage';
import AppFooter from './components/PreJoinScreens/AppFooter/AppFooter';
import VerifyOTPPage from './components/VerifyOTPPage/VerifyOTPPage';

const LoginPageWithFooter = () => {
  return (
    <div style={{ height: '100%' }}>
      <LoginPage />
      <AppFooter />
    </div>
  );
};

const VerifyOTPPageWithFooter = () => {
  return (
    <div style={{ height: '100%' }}>
      <VerifyOTPPage />
      <AppFooter />
    </div>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <SessionProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <AdminApp />
            </PrivateRoute>
            <Route path="/login">
              <LoginPageWithFooter />
            </Route>
            <Route path="/verify-otp">
              <VerifyOTPPageWithFooter />
            </Route>
            <Redirect to="/" />
          </Switch>
        </SessionProvider>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
