import { Server } from '../../server';
import { CustomerDetails, RoomDetails } from '../../types';
import { BrandConfig } from './conference-types';

const fetchSession = async (conferenceSessionToken: string): Promise<any> => {
  return await Server.fetchSession(conferenceSessionToken);
};

const getAgentVideoToken = async (conferenceSessionId: string, roomName: string): Promise<any> => {
  const videoToken = await Server.getAgentVideoToken(conferenceSessionId, roomName);
  return { videoToken, ok: true };
};

const createLiveConferenceSession = async (customerDetails: CustomerDetails): Promise<RoomDetails> => {
  const roomDetails = await Server.createLiveConferenceSession(customerDetails);
  return roomDetails;
};

const getBrandBaseConfig = async (): Promise<BrandConfig> => {
  return await Server.getBrandBaseConfig();
};

export { getAgentVideoToken, getBrandBaseConfig, createLiveConferenceSession, fetchSession };
