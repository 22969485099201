import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    // background: '#ffffff00',
    textAlign: 'center',
    padding: '25px 0',
    fontSize: '14px',
    position: 'relative',
    zIndex: 100,
  },
  footerContainer: {
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    left: 0,
    right: 0,
    height: '80px',
    // background: 'rgba(0, 0, 0, 0.5)',
    '& h6': {
      color: 'white',
    },
    '& button': {
      background: 'white',
      color: theme.brand,
      border: `2px solid ${theme.brand}`,
      margin: '0 0em',
      '&:hover': {
        color: '#600101',
        border: `2px solid #600101`,
        background: 'transparent',
      },
    },
  },
  footerMessage: {
    color: '#ffffff',
    fontSize: '14px',
  },
}));

export default function AppFooter() {
  const classes = useStyles();
  return (
    <Grid container justify="center" alignItems="center" className={classes.footerContainer}>
      <Typography variant="h6" className={classes.footerMessage}>
        Powered by VOLLETO LIVE
      </Typography>
    </Grid>
  );
}
