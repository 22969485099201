import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  email,
  minLength,
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

export const UserCreate = props => {
  const transform = data => ({
    ...data,
    roles: ['liveChatAgent'],
  });

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="iid" validate={required()} />
        <TextInput label="brand codename" source="brandCodename" validate={required()} />
        <TextInput source="email" validate={email()} />
        <TextInput source="name" validate={required()} />
        <TextInput source="serviceEmail" validate={email()} />
        <TextInput source="whatsappNumber" />
        <TextInput source="mobileNumber" />
      </SimpleForm>
    </Create>
  );
};
