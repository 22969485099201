import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { UsersListFilter } from './UsersListFilter';

export const UsersList = props => (
  <List {...props} filters={<UsersListFilter />}>
    <Datagrid>
      <TextField source="brandCodename" />
      <TextField source="roles" />
      <TextField source="email" />
      <TextField source="name" />
      <TextField source="iid" />
      <TextField label="enabled" source="liveChat.enabled" />
      <TextField source="whatsappNumber" />
      <TextField source="mobileNumber" />
      <DateField source="_created_at" showTime />
    </Datagrid>
  </List>
);
