import React, { createContext, Dispatch, ReactNode, SetStateAction } from 'react';

import { AgentDetails, CustomerDetails } from '../../types';
import useConferenceParticipants from '../../state/useConferencePartiicipants/useConferenceParticipants';

/*
 *  The hooks used by the SessionProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'SessionProvider/' directory are intended to be used by the SessionProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface ISessionContext {
  customer?: CustomerDetails;
  agent?: AgentDetails;
  setCustomer: Dispatch<SetStateAction<CustomerDetails | undefined>>;
  setAgent: Dispatch<SetStateAction<AgentDetails | undefined>>;
  isAgent: boolean | undefined;
  isCustomer: boolean | undefined;
}

export const SessionContext = createContext<ISessionContext>(null!);

interface SessionProviderProps {
  children: ReactNode;
}

export function SessionProvider({ children }: SessionProviderProps) {
  const { customer, agent, setCustomer, setAgent, isAgent, isCustomer } = useConferenceParticipants();

  return (
    <SessionContext.Provider
      value={{
        agent,
        customer,
        setCustomer,
        setAgent,
        isAgent,
        isCustomer,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
