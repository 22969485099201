import { useCallback, useEffect, useState } from 'react';
import { AgentDetails } from '../../types';
import { StorageWrapper } from '../../storage-wrapper';
import {
  login,
  verifyOneTimePasscode,
  resetPasswordRequest,
  setNewPasswordRequest,
  validateNewPasswordTokenRequest,
  resendOTP,
} from '../../apis/auth/auth-api';

export function getErrorMessage(message?: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function useVolletoAuth() {
  const userModel = StorageWrapper.getUserModel();
  // console.log('USER_MODEL', userModel);
  const [user, setUser] = useState<AgentDetails | undefined>(userModel);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isIntermediateAuthReady, setIsIntermediateAuthReady] = useState(false);

  useEffect(() => {
    // console.log('SET TOkens');
    const authToken = StorageWrapper.getAuthToken();
    // console.log('SET TOkens authToken', authToken);
    if (authToken) {
      setIsAuthReady(true);
    }
    const interAuthToken = StorageWrapper.getIntermediateToken();
    // console.log('SET TOkens interAuthToken', interAuthToken);
    if (interAuthToken) {
      setIsIntermediateAuthReady(true);
    }
  }, []);

  const signIn = useCallback((email: string, password: string) => {
    // console.log('SignIn', email, password);
    return login(email, password).then(res => {
      // console.log('SignIn::RES', res);
      if (res?.token) {
        const { token } = res;
        // const user = res.user;
        // StorageWrapper.storeUserModel(user);
        StorageWrapper.storeIntermediateToken(token);
        setIsIntermediateAuthReady(true);
        setIsAuthReady(false);
      } else {
        StorageWrapper.storeIntermediateToken(undefined);
        throw new Error(getErrorMessage(res?.error?.message));
      }
    });
  }, []);

  const verifyOTP = useCallback((code: string) => {
    return verifyOneTimePasscode(code).then(res => {
      // console.log('verifyOTP::RES', res);
      if (res?.token) {
        // console.log('verifyOTP::SETTING VALUES', res);
        const { token, user } = res;
        StorageWrapper.storeUserModel(user);
        StorageWrapper.storeAuthToken(token);
        StorageWrapper.storeIntermediateToken(undefined);
        setUser(user as any);
        setIsIntermediateAuthReady(false);
        setIsAuthReady(true);
      } else {
        throw new Error(getErrorMessage(res?.error?.message));
      }
    });
  }, []);

  const resendOTPRequest = useCallback(() => {
    return resendOTP();
  }, []);

  const resetPasswordRequestForAgent = useCallback((email: string) => {
    return resetPasswordRequest(email).then(res => {
      // console.log('resetPasswordRequestForAgent::RES', res);
    });
  }, []);

  const validateNewPasswordToken = useCallback((token: string) => {
    return validateNewPasswordTokenRequest(token);
  }, []);

  const setNewPasswordRequestForAgent = useCallback((perishableToken: string, newPassword: string) => {
    return setNewPasswordRequest(perishableToken, newPassword).then(res => {
      // console.log('resetPasswordRequestForAgent::RES', res);
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    StorageWrapper.storeAuthToken(undefined);
    StorageWrapper.storeUserModel(undefined);
    StorageWrapper.storeCurrentSessionId(undefined);
    return Promise.resolve();
  }, []);
  // console.log('VOLLETO_ATH', user, isAuthReady, roomType);
  return {
    user,
    isAuthReady,
    isIntermediateAuthReady,
    signIn,
    signOut,
    verifyOTP,
    resendOTPRequest,
    resetPasswordRequestForAgent,
    setNewPasswordRequestForAgent,
    validateNewPasswordToken,
  };
}
