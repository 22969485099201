import { useEffect, useState } from 'react';
import { AgentDetails, CustomerDetails } from '../../types';
import { useAppState } from '../index';
import { StorageWrapper } from '../../storage-wrapper';
import { isEmpty, isNil } from 'lodash';

export default function useConferenceParticipants() {
  const [customer, setCustomer] = useState<CustomerDetails | undefined>(undefined);
  const [agent, setAgent] = useState<AgentDetails | undefined>(undefined);
  const [isAgent, setIsAgent] = useState<boolean | undefined>(undefined);
  const [isCustomer, setIsCustomer] = useState<boolean | undefined>(undefined);
  const { user } = useAppState();

  useEffect(() => {
    const token = StorageWrapper.getAuthToken();
    const _isAgent = !isNil(token) && !isNil(user) && !isEmpty(user);
    if (_isAgent) {
      setAgent(user);
    }
    setIsAgent(_isAgent);
    const _isCustomer = !token && !!customer;
    setIsCustomer(_isCustomer);
    return () => {};
  }, [user, customer, agent]);
  return {
    customer,
    setCustomer,
    agent,
    setAgent,
    isAgent,
    isCustomer,
  };
}
