import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomerDetails, RoomType } from '../../types';
import { isEmpty } from 'lodash';
import {
  getAgentVideoToken,
  createLiveConferenceSession,
  fetchSession,
  getBrandBaseConfig,
} from '../../apis/conference/conference-api';
import { BrandConfig } from '../../apis/conference/conference-types';

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function useConferenceSession() {
  const history = useHistory();
  // const userModel = StorageWrapper.getUserModel();
  // console.log('USER_MODEL', userModel);
  // const [user, setUser] = useState<AgentDetails | undefined>(userModel);
  // const [isAuthReady, setIsAuthReady] = useState(false);
  // const [isIntermediateAuthReady, setIsIntermediateAuthReady] = useState(false);
  const [brandConfig, setBrandConfig] = useState<BrandConfig>({});
  const [roomType, setRoomType] = useState<RoomType>();

  const getRoomToken = useCallback((conferenceSessionId: string, roomName: string) => {
    return (
      getAgentVideoToken(conferenceSessionId, roomName)
        .then(async res => {
          if (res.ok) {
            return res;
          }
          // const json = await res.json();
          const errorMessage = getErrorMessage(res.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        // .then(res => res.json())
        .then(res => {
          setRoomType(res.room_type);
          return res.videoToken as string;
        })
    );
  }, []);

  const fetchCustomerSession = useCallback((conferenceSessionToken: string) => {
    return fetchSession(conferenceSessionToken).then(res => {
      // console.log('fetchCustomerSession', res);
      return res;
    });
  }, []);

  const createRoom = useCallback((customerDetails: CustomerDetails) => {
    return createLiveConferenceSession(customerDetails).then(res => {
      return res;
    });
  }, []);

  useEffect(() => {
    // const authToken = StorageWrapper.getAuthToken();
    // console.log('useConferenceSession', brandConfig);
    if (isEmpty(brandConfig)) {
      getBrandBaseConfig().then(config => {
        setBrandConfig(config);
      });
    }
  }, [history, brandConfig]);

  return {
    getToken: getRoomToken,
    roomType,
    createRoom,
    fetchCustomerSession,
    brandConfig,
  };
}
