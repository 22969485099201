import * as React from 'react';
import { Filter, TextInput } from 'react-admin';

export const ActivitiesListFilter = props => (
  <Filter {...props}>
    <TextInput label="Brand" source="brandCodename" alwaysOn />
    <TextInput label="Room Name" source="roomName" alwaysOn />
    <TextInput label="Room Event" source="roomEventType" alwaysOn />
  </Filter>
);
