import * as React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import UserIcon from '@material-ui/icons/People';
import { ActivitiesList } from './activities/ActivitiesList';
import { UsersList } from './users/UsersList';
import authProvider from './providers/auth-provider';
import useVolletoAuth from '../state/useVolletoAuth/useVolletoAuth';
import { UserCreate } from './users/UserCreate';
import { UserEdit } from './users/UserEdit';
import { StorageWrapper } from '../storage-wrapper';

const fetchJson = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      authorization: StorageWrapper.getAuthToken(),
    });
  }
  options.user = {
    authenticated: true,
    token: StorageWrapper.getAuthToken(),
  };
  // add your own headers here
  return fetchUtils.fetchJson(url, options);
};

// import jsonServerProvider from 'ra-data-json-server';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const prefix = isLocalhost ? 'http' : 'https';

const dataProvider = simpleRestProvider(`${prefix}://${window.location.host}/api/admin`, fetchJson);
// console.log('Location', window && window.location.host);
const AdminApp = () => {
  const { signOut } = useVolletoAuth();
  const updatedAuthProvider = Object.assign(authProvider, { logout: signOut });
  return (
    <Admin dataProvider={dataProvider} authProvider={updatedAuthProvider}>
      <Resource name="users" list={UsersList} create={UserCreate} edit={UserEdit} icon={UserIcon} />
      <Resource name="activity" list={ActivitiesList} />
    </Admin>
  );
};

export default AdminApp;
