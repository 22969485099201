import { StorageWrapper } from '../../storage-wrapper';

export default {
  login: async ({ username, password }) => {
    console.log('LOGIN');
  },
  getIdentity: async () => {
    return StorageWrapper.getUserModel();
  },
  logout: async (): Promise<void> => {
    console.log('LOGOUT');
    StorageWrapper.storeUserModel(undefined);
    StorageWrapper.storeAuthToken(undefined);
  },
  checkError: async error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // login: params => Promise.resolve(),
  // logout: params => Promise.resolve(),
  checkAuth: params => Promise.resolve(),
  // checkError: error => Promise.resolve(),
  getPermissions: params => Promise.resolve(),
  // getIdentity: () => Promise.resolve(),
};
